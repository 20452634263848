<template>
  <div>
        <h3>Assinar contrato</h3>

        <div class="sub-header">
            <p>Por favor, use o espaço em branco abaixo para rubricar sua assinatura</p>
            <button class="button-undo" @click="undo">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M7 19v-2h7.1q1.575 0 2.737-1Q18 15 18 13.5T16.837 11q-1.162-1-2.737-1H7.8l2.6 2.6L9 14 4 9l5-5 1.4 1.4L7.8 8h6.3q2.425 0 4.163 1.575Q20 11.15 20 13.5q0 2.35-1.737 3.925Q16.525 19 14.1 19Z"/></svg>
                Desfazer
            </button>
        </div>

        <div class="container-signature-pad">
            <VueSignaturePad height="500px" ref="signaturePad" :options="signOptions"/>
        </div>
        <div class='containerButtonProximo'>
          <button class="buttonAnterior" @click="voltar">Anterior</button>
          <button class="buttonProximo" @click="salvarAssinatura">PRÓXIMO</button>
      </div>
    </div>
</template>

<script>
export default {
  data: () => ({
    signOptions: {
    minWidth: 0.5,
    maxWidth: 3.5
    },
  }),
  methods: {
    undo() { this.$refs.signaturePad.undoSignature() },
    teste() {
      const { data } = this.$refs.signaturePad.saveSignature();
      return data;
    },
    salvarAssinatura() {
      const { data } = this.$refs.signaturePad.saveSignature();
      this.$emit('emit-imagem', data)
    },

    voltar() {
      console.log('chegou aqui')
      this.$emit('voltarEtapa', 'assinar')
    }
  },
}
</script>

<style lang="scss" scoped>
 
 .button-undo{
   width: auto;
   height: 34px;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   border: solid 1px #000;
   border-radius: 5px;
   padding: 4px;
   margin-top: 10px;
 }

 .container-signature-pad{
    border: solid 2px #000;
    border-radius: 5px;
    margin-bottom: 12px;
    margin-top: 10px;
 }

  .buttonProximo {
    background-color: #1377F2;
    color: white;
    padding: 10px;
    width: 100px;
    border-radius: 5px;
  }
  .containerButtonProximo {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .buttonAnterior {
    background-color: #1377F2;
    color: white;
    padding: 10px;
    width: 100px;
    border-radius: 5px;
  }

</style>