<template>
<div class="containerPrincipal">
    <div class="containerCentral">
      <div class="menuEtapas">
        <button :class="modalFormulario ? 'buttonEtapasSelecionado' : 'buttonEtapasNaoSelecionado'">Formulário</button>
        <div class="linhaEntreOsBotoes"></div>
        <button :class="modalPreVisualizarContrato ? 'buttonEtapasSelecionado' : 'buttonEtapasNaoSelecionado'">Pré-visualizar contrato</button>
        <div class="linhaEntreOsBotoes"></div>
        <button :class="modalAssinarContrato ? 'buttonEtapasSelecionado' : 'buttonEtapasNaoSelecionado'">Assinar</button>
        <div class="linhaEntreOsBotoes"></div>
        <button :class="modalVisualizarContrato ? 'buttonEtapasSelecionado' : 'buttonEtapasNaoSelecionado'">Visualizar contrato</button>
      </div>
      <div style="width: 100%; display: flex; justify-content: center; align-items: center;">
        <button class="buttonEtapasSelecionado" id="etapaButton" style="width: 50%; display: none;">{{tituloEtapa}}</button>
      </div>
      <div class="divisao"></div>
      <div v-if="!concluido">
        <ModalFormularioDadosDoCliente @emit-cliente-dados="dadosDoCliente" :cliente="cliente" v-if="modalFormulario" />
        <ModalPreVisualizarContrato :stackholdersCriados="stackholdersCriados" :cliente="cliente" @assinarContrato="contratoPreMontado" :dadosCliente="dadosCliente" v-if="modalPreVisualizarContrato" @voltarEtapa="voltar"/>
        <ModalAssinarContrato @emit-imagem="imagemAssinatura" v-if="modalAssinarContrato" @voltarEtapa="voltar"/>
        <ModalVisualizarEConcluir :dadosCliente="dadosCliente" :contrato="visualizarContrato" v-if="modalVisualizarContrato" :imagem="this.assinatura" @voltarEtapa="voltar" @concluir="salvarTermoEEnviarViaEmail"/>
      </div>
      <div v-if="concluido">
        <p>Obrigado!</p>
      </div>
    </div>
</div>
</template>


<script>
import ModalFormularioDadosDoCliente from './Modal/FormularioDadosDoCliente.vue';
import ModalPreVisualizarContrato from './Modal/PreVisualiazrContrato.vue';
import ModalAssinarContrato from './Modal/AssinarContrato.vue';
import ModalVisualizarEConcluir from './Modal/VisualizarContrato.vue';
// import {alertar} from '@/services/notificacao.service.js';
import termoAdesao from '../../services/termo_de_adesao.service.js';
import stakeholderService from '../../services/stakeholder.service';
export default {
  components: {
    ModalFormularioDadosDoCliente,
    ModalPreVisualizarContrato,
    ModalAssinarContrato,
    ModalVisualizarEConcluir
  },
  data() {
    return {
      modalFormulario: true,
      modalPreVisualizarContrato: false,
      modalAssinarContrato: false,
      visualizarContrato: null,
      modalVisualizarContrato: false,
      etapa: 1,
      assinatura: null,
      dadosCliente: null,
      contrato: null,
      cliente: null,
      concluido: false,
      tituloEtapa: 'Formulário'
    }
  },
  created(){
      localStorage.setItem('tktaskcheck', 'token')
    },
  async mounted() {
    this.requisitarcliente()
    },
  methods: {

    async requisitarcliente(){
      const { id } = this.$route.params
      await stakeholderService.buscarCliente({id})
      .then((resp) => {
        this.cliente = resp.data[0]
        console.log('console', resp.data[0])
      })
      .catch(() => {
        console.log('deu erro')
      })
    },
  
    async salvarTermoEEnviarViaEmail() {
      const { id } = this.$route.params;
      const obj = {
        idcliente: id,
        email: this.cliente.contato1
      };

      termoAdesao.salvar(obj)
      .then(() => {
        this.concluido = true;
      })
      .catch(() => {
        console.log('deu erro')
      })
    },


   async imagemAssinatura(imagem){
      this.assinatura = imagem;
      const { id } = this.$route.params
      let assinaturaImg = `<img src="${imagem}" style="width: 250px" alt="assinatura" />`
      await termoAdesao.salvarAssinaturaCliente({ idcliente: id, assinatura: assinaturaImg })
      .then((res) => {
        this.modalAssinarContrato = false;
        this.tituloEtapa = 'Visualizar contrato'
        this.modalVisualizarContrato = true;
        console.log(res)
      })
      .catch((error) => {
        console.log(error)
      })
    },

    async dadosDoCliente(payload) {
      this.stackholdersCriados = payload.stackholdersCriados
      this.modalFormulario = false;
      this.tituloEtapa = 'Pré-visualizar contrato'
      this.modalPreVisualizarContrato = true;
    },

    contratoPreMontado() {
      this.modalPreVisualizarContrato = false;
      this.tituloEtapa = 'Assinar'
      this.modalAssinarContrato = true;
    },

    voltar(tela) {
      console.log(tela)
      if(tela === 'pré-visualizar') {
        this.tituloEtapa = 'Formulário'
        this.modalPreVisualizarContrato = false;
        this.modalFormulario = true;
      }

      if(tela === 'assinar') {
        this.tituloEtapa = 'Pré-visualizar contrato'
        this.modalAssinarContrato = false;
        this.modalPreVisualizarContrato = true;
      }

      if(tela === 'visualizar') {
      this.tituloEtapa = 'Assinar'
        this.modalVisualizarContrato = false;
        this.modalAssinarContrato = true;
      }
    }
  },
}
</script>

<style scoped>
.containerPrincipal {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.containerCentral {
  display: flex;
  flex-direction: column;;
  background-color: rgb(255, 255, 255);
  width: 80%;
  padding: 20px;
}

.menuEtapas {
  display: flex;
  width: 70%;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  margin: auto;
}
.buttonEtapasSelecionado {
  background-color: #1377F2;
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.buttonEtapasNaoSelecionado {
  background-color: #8ab5ff;
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.linhaEntreOsBotoes {
  background-color: #1377F2;
  flex: 1;
  height: 3px;
}

.divisao {
  width: 100%;
  height: 1px;
  background-color: #d3d3d3;
  margin-top: 20px;
  margin-bottom: 20px;
}
.containerButtonProximo {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .containerButtonProximoSpaceEnd {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .buttonProximo {
    background-color: #1377F2;
    color: white;
    padding: 10px;
    width: 100px;
    border-radius: 5px;
  }

  .buttonAnterior {
    background-color: #1377F2;
    color: white;
    padding: 10px;
    width: 100px;
    border-radius: 5px;
  }

  @media only screen and (max-width: 982px){
  .menuEtapas {
    display: none;
  }
  #etapaButton {
    display: block!important;
  }

}

@media only screen and (max-width: 458px){

  .containerCentral {
    width: 100%;
  }
}
</style>