<template>
  <div class="containerPrincipal">
    <div class="containerInputs1">
      <div style="margin-bottom: 10px;">
        <p><strong>Formulário</strong></p>
      </div>
      <div class="containerRazaoSocialENomefantasia">
        <input class="inputTextRazaoSocialENomeFantasia" placeholder="Nome Cliente" v-model="endereço.nome" type="text" />
        <input class="inputTextRazaoSocialENomeFantasia" placeholder="Razão social" v-model="endereço.razaoSocial" type="text" />
        <input class="inputTextRazaoSocialENomeFantasia" placeholder="Nome fantasia" v-model="endereço.nomeFantasia" type="text" />
      </div>
      <input class="inputTextCNPJ" placeholder="CNPJ" v-model="endereço.cnpj" type="text" />
    </div>
    <div class="containerInputs2">
      <div  class="inputContainerEstiloCep">
       <input class="inputCep" placeholder="Cep" v-model="endereço.cep" type="text" />
        <v-btn
         height="56px"
         elevation="0"
         :ripple="false"
         class="btn rounded-l-0"
         data-cy="btn-envio"
         color="#1377F2"
         @click="buscarCep"
         >
         <svg class="button-content" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/></svg>
        </v-btn>
      </div>
      <input class="inputTextEstiloGeralEndereço" v-model="endereço.cidade" placeholder="cidade" type="text" />
      <input class="inputTextEstiloGeralEndereço" v-model="endereço.Estado" placeholder="Estado" type="text" />
      <input class="inputTextEstiloGeralEndereço" v-model="endereço.rua" placeholder="Rua" type="text" />
      <input class="inputTextEstiloGeralEndereço" v-model="endereço.Bairro" placeholder="Bairro" type="text" />
      <input class="inputTextEstiloGeralEndereço" v-model="endereço.Número" placeholder="Número" type="text" />
    </div>
    <div class="divisao"></div>
    <p><strong>Sistemas Contratados</strong></p>
    <div v-for="(sistema, index) in SistemasDoCliente" :key="index" class="containerSistemasCadastrados">
      <div>
        <li style="font-size: larger; font-weight: bolder;">. {{sistema.nome_sistema}}</li>
      </div>
      <div v-if="sistema.stackHolder.length > 0">
        <li v-for="(stackholder, index) in sistema.stackHolder" :key="index">- {{stackholder.tipo.nome}}: {{stackholder.nome}}</li>
      </div>
      <div>
      <button class="buttonCriarStackHolder" @click="() => {
        dadosStakeHolder.sistema = sistema
        exibirModalCriarStakeHolder = true
        } ">CADASTRAR ENVOLVIDOS</button>
    </div>
    </div>

    <v-dialog v-model="exibirModalCriarStakeHolder" width="500px" max-width="80vw">
          <div style="background-color:white;width:100%;display:flex;flex-direction:column;padding: 10px;">  
            <div style="text-align: center;margin-bottom: 20px;margin-top: 20px;">
              <p style="font-size: large;font-weight: bold;">Criar stakeholder</p>
            </div>  
            <div class="containerInputs2">
            <v-select class="select-input" disabled color v-model="dadosStakeHolder.sistema" label="Sistema"  item-text="nome_sistema" item-value="id_sistema" :items="SistemasDoCliente"
            outlined return-object hide-details dense>
            </v-select>
            <input class="inputTextEstiloNomeCargo" v-model="dadosStakeHolder.nome" placeholder="Nome" type="text" />
            <input class="inputTextEstiloGeralResponsaveis" v-model="dadosStakeHolder.email" placeholder="Email" type="text" />
            <input class="inputTextEstiloGeralResponsaveis" v-model="dadosStakeHolder.telefone" placeholder="Telefone" type="text" />
            <v-select class="select-input" color v-model="dadosStakeHolder.tipo" label="Tipo stakeholder"        item-text="nome" item-value="id" :items="tipoStakeHolder"
            outlined return-object hide-details dense>
            </v-select>
            <div class="containerButtonCriarStakeHolder">
              <button class="buttonProximo" style="margin-right: 10px;" @click="() => this.exibirModalCriarStakeHolder = false">CANCELAR</button>
              <button class="buttonProximo" @click="criarStakeHolder">CRIAR</button>
            </div>
          </div>
          </div>
    </v-dialog>
   
    <div class='containerButtonProximoSpaceEnd'>
      <button class="buttonProximo" @click="salvar">PRÓXIMO</button>
     </div>
  </div>
  </template>
  
  
  <script>
  import cep from 'cep-promise';
  import sistemasCliente from '@/services/sistemasCliente.service.js';
  import StakeHServico from '@/services/stakeholder.service.js';
  import {alertar} from '@/services/notificacao.service.js';
  import implantacao from '../../../services/implantacao.service';
  import termo_de_adesaoService from '../../../services/termo_de_adesao.service';
  import { cnpj } from 'cpf-cnpj-validator';
  export default {
    props: ['cliente'],
    data() {
      return {
        idCliente: null,
        SistemasDoCliente: null,
        implantacoes: null,
        dadosStakeHolder: {
          tipo: null,
          sistema: null,
          nome: null,
          email: null,
          telefone: null
        },
        dadosResponsaveis: {
          Nome: null,
          Cargo: null,
          Email: null,
          Telefone: null,
          responsabilidades: null,
          
        },
        exibirModalCriarStakeHolder: false,
        endereço: {
          nome: null,
          rua: null,
          Número: null,
          Estado: null,
          cidade: null,
          Bairro: null,
          cep: null,
          razaoSocial: null,
          nomeFantasia: null,
          cnpj: null,
        },
        tipoStakeHolder: [],
        stackholdersCriados: [],
        contrato: [],
  
      }
    },
    methods: {
      buscarCep(){
       cep(Number(this.endereço.cep))
      .then(resp => {
        console.log(resp)
        this.endereço.cidade = resp.city;
        this.endereço.Estado = resp.state;
      })
      .catch(error => {
        alert(error);
      })
      },

      async requisitarSistemasDoCliente(){
      const buscaSistemas = await sistemasCliente.buscar({id: this.idCliente}).then(res=> res)
      buscaSistemas.data.forEach((e) => e.stackHolder = []);
      console.log(buscaSistemas.data);
      this.SistemasDoCliente = buscaSistemas.data;
    },
    
    async criarStakeHolder() {
      if(this.dadosStakeHolder.nome === null 
      || this.dadosStakeHolder.telefone === null
      || this.dadosStakeHolder.email === null
      || this.dadosStakeHolder.tipo === null
      ) {
        return  alertar('warning', 'ATENÇÃO', 'É necessário preencher todos os dados!')
      }

      const { id } = this.$route.params

      const id_sistema = Number(this.dadosStakeHolder.sistema.id_sistema);
   
      const filterImplantacao = this.implantacoes.filter((e) => e.idsistema === id_sistema);

      if(filterImplantacao.length === 0) {
        alertar('success', '=)', 'Não é possível criar um stackholder sem uma implantação!')
        return
      }
      const dadosusuario = {
          principal: true,
          nome: this.dadosStakeHolder.nome,
          telefone: this.dadosStakeHolder.telefone,
          email: this.dadosStakeHolder.email,
          idimplantacao: filterImplantacao[0].id,
          idambiente: 2,
          idtipo: this.dadosStakeHolder.tipo.id,
          idcliente: Number(id)
        }

      await StakeHServico.criar(dadosusuario).then(() => {
          this.exibirModalCriarStakeHolder = false;
          this.SistemasDoCliente.forEach((e) => {
            if(e.id_sistema === id_sistema) {
              e.stackHolder = [...e.stackHolder, { nome: this.dadosStakeHolder.nome, tipo: this.dadosStakeHolder.tipo }]
            }
          })
          console.log(this.SistemasDoCliente)
          // this.stackholdersCriados = [...this.stackholdersCriados, { nome: this.dadosStakeHolder.nome, tipo: this.dadosStakeHolder.tipo }];
          alertar('success', '=)', 'Stakeholder criado com sucesso!')
        }).catch(e => {
          console.log('error criarcao stakeholder', e)
          alertar('warning', '=/', 'Falha na criação do StakeHolder')
        })
      },

     async requisitarStakeHolderTipo() {
        await StakeHServico.buscarTipoCliente().then(res => {
          this.tipoStakeHolder = res.data;
        }).catch(() => {
          alertar('warning', '=/', 'Falha ao buscar StakeHolder')
        })
     }, 

     async requisitarImplantacaoCliente() {
      const { id } = this.$route.params
      const data = {
        idcliente: id
      }
      await implantacao.buscarImplantacaoCliente(data).then(res => {
        console.log(res.data, 'impltacoes')
          this.implantacoes = res.data;
        }).catch(() => {
          alertar('warning', '=/', 'Falha ao buscar StakeHolder')
        })
     },

     nulo_vazio(campos) {
        if(campos === null || campos === '') {
          return true;
        }
        return false;
      },

      camposNulos() {
        if(
          this.nulo_vazio(this.endereço.razaoSocial) || this.nulo_vazio(this.endereço.nomeFantasia) ||
          this.nulo_vazio(this.endereço.cnpj) || this.nulo_vazio(this.endereço.cep) ||
          this.nulo_vazio(this.endereço.cidade) || this.nulo_vazio( this.endereço.Estado) ||
          this.nulo_vazio( this.endereço.rua) || this.nulo_vazio(this.endereço.Bairro) ||
          this.nulo_vazio(this.endereço.Número) || this.nulo_vazio(this.endereço.nome)
        ) {
          return true
        }
        return false;
      },

      validarStackholderPreenchidos() {
        const validar = this.SistemasDoCliente.every((e) => e.stackHolder.length > 0);
        return validar;
      },

     async salvar() {

      const validarCamposNulos = this.camposNulos()

        if(validarCamposNulos) {
          alertar('warning', '=/', 'Preencha todos os campos!') 
          return
        }

      const validateCnpjOrCpf = cnpj.isValid(this.endereço.cnpj);
         if(!validateCnpjOrCpf) {
           alertar('warning', '=/', 'CNPJ Inválido')
           return
          }

      const { idcontrato } = this.$route.params
      const data = {
        id: idcontrato
      }

      const validarStackholder = this.validarStackholderPreenchidos();
      if(!validarStackholder) {
        alertar('warning', '=/', 'É necessário preencher pelo menos um envolvido para cada sistema');
        return
      }
      await termo_de_adesaoService.buscarContrato(data).then(res => {
          this.contrato = res.data;
          console.log(res.data)
        }).catch(() => {
          alertar('warning', '=/', 'Falha ao buscar Contrato')
        })

        let stack = '';
        this.stackholdersCriados.forEach((e) => {
      stack += `<div><li>${e.tipo.nome}: ${ e.nome }</li></div>`
    })

      const obj = {
        idcliente: this.idCliente,
        razao_social: this.endereço.razaoSocial,
        nome_fantasia: this.endereço.nomeFantasia,
        cnpj: this.endereço.cnpj,
        cep: this.endereço.cep,
        cidade: this.endereço.cidade,
        estado: this.endereço.Estado,
        rua: this.endereço.rua,
        bairro: this.endereço.Bairro,
        numero: this.endereço.Número,
        assinatura: 'assinatura',
        contrato: this.contrato[0].contrato,
        nome: this.endereço.nome,
        stackholdersCriados: stack ? stack : '',
        idcontrato: idcontrato,
        numeroproposta: this.cliente.numeroproposta ? this.cliente.numeroproposta : 'numeroProposta'
      }
      await termo_de_adesaoService.salvarDadosCliente(obj)
      .then((res) => {
        this.$emit('emit-cliente-dados', obj)
        console.log(res)
      })
      .catch((error) => {
        alertar('warning', '=/', 'Falha')
        console.log(error)
      })
     },

     async buscarDadosClientesSalvo() {
      try {
        const { id, idcontrato } = this.$route.params
        const result = await termo_de_adesaoService.buscarDadosClientesSalvo({idcliente: id, idcontrato: idcontrato})
        // endereço: {
        //   nome: null,
        //   rua: null,
        //   Número: null,
        //   Estado: null,
        //   cidade: null,
        //   Bairro: null,
        //   cep: null,
        //   razaoSocial: null,
        //   nomeFantasia: null,
        //   cnpj: null,
        // }
        if(result.data.length > 0) {
          this.endereço.nome = result.data[0].nome
          this.endereço.rua = result.data[0].rua
          this.endereço.Número = result.data[0].numero
          this.endereço.Estado = result.data[0].estado
          this.endereço.cidade = result.data[0].cidade
          this.endereço.Bairro = result.data[0].bairro
          this.endereço.cep = result.data[0].cep
          this.endereço.razaoSocial = result.data[0].razao_social
          this.endereço.nomeFantasia = result.data[0].nome_fantasia
          this.endereço.cnpj = result.data[0].cnpj
        }
        console.log(result.data);
      } catch (error) {
        console.log(error)
      }
     },
  
     async requisitarStackHolders() {
      try {
        const implantacoes = this.implantacoes.map((e) => e.id);
        if(implantacoes.length > 0) {
          const result = await termo_de_adesaoService.buscarStakeHolders(implantacoes);
          console.log(result.data);
          result.data.forEach((e) => {
            this.SistemasDoCliente.forEach((e2) => {
              if(e.idsistema === e2.id_sistema) {
                e2.stackHolder = [...e2.stackHolder, { nome: e.nomeusuario, tipo: { nome: e.tipousuario } }]
              }
            })
          })
        }
        console.log(implantacoes)
      } catch (error) {
        console.log(error)
      }
     }

    },
    async mounted() {
      const { id } = this.$route.params
      this.idCliente = id;
      await this.requisitarSistemasDoCliente();
      await this.requisitarStakeHolderTipo();
      await this.requisitarImplantacaoCliente();
      await this.buscarDadosClientesSalvo();
      await this.requisitarStackHolders();
    },
  }
  </script>
  
  <style scoped>
  .containerPrincipal {
    display: flex;
    flex-direction: column;
  }

  input {
    background-color: white;
    border: 1px solid rgb(168, 168, 168);
    border-radius: 8px;
    padding: 10px;
  }

  .containerInputs1 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .containerInputs2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .inputTextRazaoSocialENomeFantasia {
    width: 32%;
  }

  .inputTextCNPJ {
    width: 100%;
  }

  .containerRazaoSocialENomefantasia {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .inputTextEstiloGeralEndereço {
    width: 48%;
    margin-bottom: 10px;
  }

  .inputContainerEstiloCep {
    display: flex;
    width: 48%;
    margin-bottom: 10px;
  }

  .inputCep {
    width: 100%;
    border-radius: 10px 0 0 10px;
  }

  .containerButtonProximo {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .buttonProximo {
    background-color: #1377F2;
    color: white;
    padding: 10px;
    width: 100px;
    border-radius: 5px;
  }

  .containerButtonAdicionar {
    display: flex;
    margin-top: 5px;
  }

  .buttonAdicionar {
    background-color: #1377F2;
    color: white;
    padding: 10px;
    border-radius: 5px;
  }

  .btnCadastrar {
    border: 1px solid rgb(183, 183, 183);
    display: flex;
    align-items: center;
    padding: 5px;
    margin-left: 5px;
    border-radius: 5px;
    color: rgb(105, 105, 105);
    transition: 300ms;
  }
  .btnCadastrar:hover {
    background-color: #eaeaea;
    transition: 300ms;
  }

  .containerSistemasCadastrados {
    border: 1px solid rgb(156, 156, 156);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .containerTituloButtonSistemasCadastrados {
    display: flex;
    align-items: center;
  }

  img {
    margin-left: 5px;
    width: 20px;
  }

  .inputTextEstiloGeralResponsaveis {
    width: 48%;
    margin-bottom: 10px;
  }

  .inputResponsabilidades {
    width: 100%;
    margin-bottom: 10px;
  }

  .inputTextEstiloNomeCargo {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .buttonCriarStackHolder {
    background-color: #1377F2;
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    width: 200px;
    margin: 10px 0 0 0;
  }

.divisao {
  width: 100%;
  height: 1px;
  background-color: #d3d3d3;
  margin-top: 20px;
  margin-bottom: 20px;
}

.containerButtonCriarStakeHolder {
  width: 100%;
  margin-top: 10px;
}

.buttonProximo {
    background-color: #1377F2;
    color: white;
    padding: 10px;
    width: 100px;
    border-radius: 5px;
  }

  .containerButtonProximoSpaceEnd {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  </style>