<template>
  <div>
     <div v-html="contrato" style="width: 100%; overflow: auto;"></div>
     <div class='containerButtonProximo'>
       <button class="buttonAnterior" @click="voltar">Anterior</button>
       <button class="buttonProximo" @click="proximo">PRÓXIMO</button>
      </div>
  </div>
</template>

<script>
  import termoAdesao from '../../../services/termo_de_adesao.service';
  import {alertar} from '@/services/notificacao.service.js';
  import sistemasCliente from '@/services/sistemasCliente.service.js';
export default {
  props:['dadosCliente', 'cliente'],
  data: () => ({
    contrato: null,
    contratoFinalizado: null,
    SistemasDoCliente: null,
  }),
  methods: {
    async requisitarContrato(){
      const { id } = this.$route.params
      const data = {
        idcliente: id
      }
      await termoAdesao.buscarContratoDoCliente(data).then(res => {
          this.contrato = res.data[0].contrato;
          console.log(res.data)
        }).catch(() => {
          alertar('warning', '=/', 'Falha ao buscar Contrato')
        })
     },

     async requisitarSistemasDoCliente(){
      const buscaSistemas = await sistemasCliente.buscar({id: this.cliente.id}).then(res=> res)
      this.SistemasDoCliente = buscaSistemas.data;
    },
    proximo() {
      this.$emit('assinarContrato')
    },

    voltar() {
      console.log('chegou aqui')
      this.$emit('voltarEtapa', 'pré-visualizar')
    }
  },  
  async mounted(){
    await this.requisitarSistemasDoCliente()
    await this.requisitarContrato();

//     console.log(this.SistemasDoCliente)
//    let sistemasContratados = [];
//     this.SistemasDoCliente.forEach((e) => {
//       sistemasContratados = [...sistemasContratados, e.nome_sistema];
//     });
//     const myString = sistemasContratados.join("<br /> ");
//     console.log(myString)


//   this.contratoTexto = this.contrato[0].contrato
//   let contratoReplace = this.contratoTexto
//   let listaSubstituicao = {
//   "@cnpj": this.dadosCliente.cnpj,
//   "@bairro": this.dadosCliente.Bairro,
//   "@numeroCasa": this.dadosCliente.Número,
//   "@cidade": this.dadosCliente.cidade,
//   "@nomeFantasia": this.dadosCliente.nomeFantasia,
//   "@rua": this.dadosCliente.rua,
//   "@cep": this.dadosCliente.cep,
//   "@estado": this.dadosCliente.Estado,
//   "@razaoSocial": this.dadosCliente.razaoSocial,
//   "@sistemas": myString
//   };

//  const alteracaoContrato = contratoReplace.replace(/@sistemas|@cnpj|@bairro|@numeroCasa|@razaoSocial|@nomeFantasia|@cidade|@rua|@estado|@cep/gi, function(item){
//     let it = listaSubstituicao[item];
//     let itemLista = it.replace(/(?:^|\s)\S/g, function(elemento) { return elemento.toUpperCase(); });
//     return itemLista;
// });

// this.contratoFinalizado = alteracaoContrato;
  }
}
</script>

<style lang="scss" scoped>
 .preVisualizar {
  margin-bottom: 10px;
 }

 .textContrato {
  width: 100%;
  height: 50vh;
  border: 1px solid black;
 }

 .buttonProximo {
    background-color: #1377F2;
    color: white;
    padding: 10px;
    width: 100px;
    border-radius: 5px;
  }
  .containerButtonProximo {
    margin-top: 30px;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .buttonAnterior {
    background-color: #1377F2;
    color: white;
    padding: 10px;
    width: 100px;
    border-radius: 5px;
  }
</style>