<template>
  <div>
      <div v-html="contrato">
      </div>
      <div class='containerButtonProximo'>
          <button class="buttonAnterior" @click="voltar">Anterior</button>
          <button class="buttonProximo" @click="concluir">Concluir</button>
      </div>
  </div>
</template>

<script>
   import termoAdesao from '../../../services/termo_de_adesao.service';
export default {
  data: () => ({
    assinatura: null,
    contrato: [],
  }),
  methods: {
    async requisitarContrato(){
      const { id } = this.$route.params
      const data = {
        idcliente: id
      }
      await termoAdesao.buscarContratoDoCliente(data).then(res => {
          this.contrato = res.data[0].contrato;
          console.log(res.data)
        }).catch((error) => {
          console.log(error)
        })
     },
     
    voltar() {
      console.log('chegou aqui')
      this.$emit('voltarEtapa', 'visualizar')
    },

    concluir() {
      this.$emit('concluir', 'concluir')
    }
  },
  async mounted() {
    await this.requisitarContrato();
  },
}
</script>

<style lang="scss" scoped>
 img{
  width: 20px;
 }

 .imagem {
  width: 20px;
 }

 .buttonProximo {
    background-color: #1377F2;
    color: white;
    padding: 10px;
    width: 100px;
    border-radius: 5px;
  }

  .buttonProximo {
    background-color: #1377F2;
    color: white;
    padding: 10px;
    width: 100px;
    border-radius: 5px;
  }
  .containerButtonProximo {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .buttonAnterior {
    background-color: #1377F2;
    color: white;
    padding: 10px;
    width: 100px;
    border-radius: 5px;
  }
</style>